<template>
    <div>
      <div class="contain1">
        <div>
            分类
            <el-select clearable @change="inquire" size="mini" style="width:130px;" class="m1150 select230" v-model="searching.itemType" placeholder="请选择">
                <el-option
                    v-for="item in userTypes"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
            </el-select>
            状态
            <el-select clearable @change="inquire" size="mini" style="width:130px;" class="m1150 select230" v-model="searching.generalStatus" placeholder="请选择">
                <el-option
                    v-for="item in userStatus"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
            </el-select>
            录入类型
            <el-select clearable @change="inquire" size="mini" style="width:130px;" class="m1150 select230" v-model="searching.inputType" placeholder="请选择">
                <el-option
                    v-for="item in inputTypes"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                </el-option>
            </el-select>
            检查项目
            <el-input size="mini" type="text" v-model="searching.itemName" placeholder="请输入检查项名称" class="m1150 input340"></el-input>
            <el-button size="mini" type="primary" style="margin-left:20px;" icon="el-icon-search" @click="inquire">查询</el-button>
            <el-button size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </div>
      </div>
      
      <div class="contain2">
        <div class="btn-top">
          <el-button type="primary" size="mini" icon="" @click="add">添加</el-button>
        </div>
        <el-table
            :data="tableData"
            :row-style="{height: '70px'}"
            style="width: 100%;margin: 10px 0;"
            row-key="id"
            border
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            stripe
            :header-cell-style="{background:'#f8f8f9'}">
            <el-table-column
                type="index"
                :index="indexMethod"
                label="序号"
                align="center"
                width="50">
            </el-table-column>
            <el-table-column prop="itemName" label="检查项目" align="center"></el-table-column>
            <el-table-column prop="itemNote" label="检查内容" align="left" show-overflow-tooltip width="400"></el-table-column>
            <el-table-column label="分类" align="left">
                <template slot-scope="{row}">
                    <span v-if="row.itemType==1">户内</span>
                    <span v-else-if="row.itemType==2">户外</span>
                </template>
            </el-table-column>
            <el-table-column prop="inputType" label="录入类型" align="center" width="100">
                <template slot-scope="{row}">
                    <span v-if="row.inputType == 1">单选</span>
                    <span v-else-if="row.inputType == 2">多选</span>
                </template>
            </el-table-column>
            <el-table-column label="必填项" align="center" width="80">
                <template slot-scope="{row}">
                    {{row.requiredFields == 1 ? '是' : '否'}}
                </template>
            </el-table-column>
            <el-table-column prop="serviceCondition" label="使用情况" align="center" show-overflow-tooltip width="80">
            </el-table-column>
            <el-table-column prop="generalStatus" label="状态" align="center" show-overflow-tooltip width="80">
                <template slot-scope="{row}">
                  <span v-if="row.generalStatus==1" class="used">已启用</span>
                  <span v-else-if="row.generalStatus==0" class="unused">已停用</span>
                </template>
            </el-table-column>
            <el-table-column prop="sortNum" label="排序" align="center"></el-table-column>
            <el-table-column label="操作" align="left">
              <template slot-scope="{row}">
                  <el-button type="text" size="small" @click="seeItems(row)">查看</el-button>
                  <el-button v-if="row.serviceCondition=='被使用'" type="text" size="small" @click="release(row)">再次发布</el-button>
                  <el-button v-else type="text" size="small" @click="edit(row)">编辑</el-button>
                  <el-button type="text" @click="startOrStop(row)" size="small" :style="{color: row.generalStatus==1 ? '#FF5400' : ''}">{{row.generalStatus==1 ? '停用' : '启用'}}</el-button>
              </template>
            </el-table-column>
        </el-table>
        <el-pagination
            style="text-align:center;margin: 15px 0 100px"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="searching.size"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
        <!-- 弹出层 -->
        <el-dialog
            :title="title==1?'添加检查项':'编辑检查项'"
            :visible.sync="dialogVisible"
            width="80%"
            top="10vh"
            :close-on-click-modal="false"
            @close="clearDialogForm('form')">
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form ref="form" :model="form" label-width="100px" :rules="rule" label-position="left">
                        <el-form-item label="检查项目" prop="itemName">
                            <el-input v-model="form.itemName" type="text" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                        <el-form-item label="检查内容" prop="itemNote">
                            <el-input type="textarea" v-model="form.itemNote" placeholder="请输入内容描述"></el-input>
                        </el-form-item>
                        <el-form-item label="排序" prop="sortNum">
                            <el-input v-model="form.sortNum" type="number" placeholder="请输入数字排序（数字越小越靠前）"></el-input>
                        </el-form-item>
                        <el-form-item label="分类" prop="itemType">
                            <el-radio-group v-model="form.itemType">
                                <el-radio :label="1">户内</el-radio>
                                <el-radio :label="2">户外</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="必填项" prop="requiredFields">
                            <el-radio-group v-model="form.requiredFields">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="启用状态" prop="generalStatus">
                            <el-radio-group v-model="form.generalStatus">
                                <el-radio :label="1">启用</el-radio>
                                <el-radio :label="0">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="录入类型" prop="inputType">
                            <el-radio-group v-model="form.inputType">
                                <el-radio :label="1">单选</el-radio>
                                <el-radio :label="2">多选</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="16">
                    <template>
                        <div style="margin-bottom: 10px">
                            <el-button type="primary" size="small" @click="addNewRow">添加新行</el-button>
                            <el-button type="warning" size="small" @click="delRow">批量删除</el-button>
                        </div>
                        <el-table
                        :data="form.items"
                        border
                        style="width: 100%"
                        highlight-current-row
                        @select="TableSelect"
                        ref="DeleteTableSelect"
                        >
                            <el-table-column
                              type="selection"
                              width="55">
                            </el-table-column>
                            <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
                            <el-table-column label="内容" align="center">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.content" placeholder="请输入内容" show-word-limit></el-input>
                            </template>
                            </el-table-column>
                            <el-table-column label="分类" align="center" width="120">
                            <template slot-scope="{ row }">
                                <el-select v-model="row.type" placeholder="请选择">
                                  <el-option label="正常" :value="1"></el-option>
                                  <el-option label="异常" :value="2"></el-option>
                                </el-select>
                            </template>
                            </el-table-column>
                            <el-table-column label="是否拍照" align="center" width="80">
                              <template slot-scope="{ row }">
                                  <el-switch
                                  v-model="row.takingPictures"
                                  active-color="#13ce66"
                                  :active-value="1"
                                  :inactive-value="2">
                                  </el-switch>
                              </template>
                            </el-table-column>
                            <el-table-column label="是否补充描述" align="center" width="120">
                              <template slot-scope="{ row }">
                                  <el-switch
                                  v-model="row.describe"
                                  active-color="#13ce66"
                                  :active-value="1"
                                  :inactive-value="2">
                                  </el-switch>
                              </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button v-if="title==1" type="primary" @click="submitForm('form')">确 定</el-button>
              <el-button v-else type="primary" @click="updateSubmitForm('form')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 查看检查项 -->
        <el-dialog
          title="查看检查项"
          :visible.sync="seeItemsDialog"
          width="60%">
          <el-descriptions :column="4" size="medium" border>
            <el-descriptions-item>
              <template slot="label">
                检查项目
              </template>
              {{form.itemName}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                分类
              </template>
              {{form.itemType==1 ? '户内' : '户外'}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                必填项
              </template>
              {{form.requiredFields==1 ? '是' : '否'}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                启用状态
              </template>
              {{form.generalStatus==1 ? '已启用' : '已停用'}}
            </el-descriptions-item>
            <el-descriptions-item span="2">
              <template slot="label">
                检查内容
              </template>
              {{form.itemNote}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                录入类型
              </template>
              {{form.inputType==1 ? '单选' : '多选'}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                排序
              </template>
              {{form.sortNum}}
            </el-descriptions-item>
          </el-descriptions>
          <div style="margin: 24px 0 11px">检查项</div>
          <el-table
            :data="form.items"
            style="width: 100%;"
            border
            :header-cell-style="{background:'#f8f8f9'}">
            <el-table-column
              type="index"
              :index="indexMethod"
              label="序号"
              align="center"
              width="50">
            </el-table-column>
            <el-table-column
              prop="content"
              label="内容"
              width="400">
            </el-table-column>
            <el-table-column
              label="分类">
              <template slot-scope="{row}">{{row.type==1 ? '正常' : '异常'}}</template>
            </el-table-column>
            <el-table-column
              label="是否拍照">
              <template slot-scope="{row}">
                {{row.takingPictures == 1 ? '是' : '否'}}
              </template>
            </el-table-column>
            <el-table-column
              label="是否补充描述">
              <template slot-scope="{row}">
                {{row.describe == 1 ? '是' : '否'}}
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
    </div>
    </div>
</template>
 
<script>
import {
    checkItemSave,
    checkItemUpdate,
    selectItemList,
    updateStatus
} from '../../RequestPort/tempManage/jianchaMaint.js'
export default {
    name: 'jianchaMaint',
    data () {
        return {
            tableIndex:0,
            fileList:[],
            title:'1',
            tableData: [],
            dialogVisible:false ,
            searching:{ // 顶部搜索条件
                itemType: '', // 分类 1户内 2户外
                generalStatus: '', // 启用状态
                inputType: '', // 录入类型
                itemName: '', // 项目名称/项目编码
                size: 100,
                current: 1
            },
            findObj: {},
            total: 0,
            userTypes:[{label:"户内",id:1},{label:"户外",id:2}],
            inputTypes:[{label:"单选",id:1},{label:"多选",id:2}],
            userStatus:[{label:"已启用",id:1},{label:"已停用",id:0}],
            form: { // 弹出层表单
                itemName: '', // 检查项名称
                itemNote: '', // 项目内容
                sortNum: '',
                itemType: 1,	 // 分类 1户内 2户外
                requiredFields: 1, //	必填项 1是 2否
                generalStatus: 1,	// 启用状态 0：禁用 1：启用
                inputType: 1,	 // 录入类型 1单选 2多选	
                items: [{
                    content: '',
                    type: '', // 分类1 正常 2 异常
                    takingPictures: 2, // 1 强制拍照 2 忽略拍照
                    describe: 2, // 是否补充描述 1是 2 否
                    timestamp: Date.now()
                }], // 弹出框table
            },
            chosedRow: {},
            rule:{
                itemName:[{ required: true, message: '请输入项目名称', trigger: 'blur' }],
                itemNote:[{ required: true, message: '请输入检查内容', trigger: 'blur' }],
                sortNum:[{ required: true, message: '请输入排序', trigger: 'blur' }],
                itemType:[{ required: true }],
                requiredFields:[{ required: true }],
                generalStatus:[{ required: true }],
                inputType:[{ required: true }],
            },
            seeItemsDialog: false
        }
    },
    mounted() {
      this.objCopy()
    this.getJianchaList()
  },
  methods:{
    seeItems (row) {
      this.$nextTick(() => {
        for(let key in row) {
          this.form[key] = row[key]
        }
      })
      this.seeItemsDialog = true
    },
    startOrStop (row) {
      updateStatus({
        generalStatus: row.generalStatus == 1 ? 0 : 1,
        id: row.id
      }).then(res => {
        if (res.code === 200) {
            this.getJianchaList()
          } else {
            this.$message.error(res.msg)
          }
      })
    },
    changeInputtype (e) {
      e == 0 || e == 1 ? this.form.items = [{
            content: '',
            type: '', // 分类1 正常 2 异常
            takingPictures: 2, // 1 强制拍照 2 忽略拍照
            describe: 2, // 是否补充描述 1是 2 否
            timestamp: Date.now()
        }] : this.form.items = []
    },
    TableSelect(e,index){
      console.log(e,index)

    },
    indexMethod (index) {
        return (this.searching.current - 1) * this.searching.size + index + 1
    },
    inquire () {
      this.searching.current = 1
      this.objCopy()
      this.getJianchaList()
    },
    objCopy () {
      for (let key in this.searching){
        this.findObj[key] = this.searching[key];
      }
    },
    reset () {
      this.searching = { // 顶部搜索条件
        itemType: '', // 分类 1户内 2户外
        generalStatus: '', // 启用状态
        inputType: '', // 录入类型
        itemName: '', // 项目名称/项目编码
        size: 100,
        current: 1
      }
      this.objCopy()
      this.getJianchaList()
    },
    edit (row) {
      this.title = 2
      this.$nextTick(() => {
        for(let key in row) {
          this.form[key] = row[key]
        }
      })
      this.dialogVisible = true
    },
    getJianchaList () {
        selectItemList(this.findObj).then(res => {
          if (res.code === 200) {
            console.log(res);
            let {records, total} = res.data
            this.tableData = records
            this.total = total
            if (records.length === 0 && res.data.pages > 1) {
                this.searching.current -= 1
                this.getJianchaList()
            }
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    release (row) { // 再次发布
      this.title = 1
      this.$nextTick(() => {
        for(let key in row) {
          this.form[key] = row[key]
        }
      })
      this.dialogVisible = true
    },
    add () { // 添加
      this.title = 1
      delete this.form.deleteflag
      delete this.form.id
      for (let key in this.form) {
          if (key == 'itemType' || key == 'requiredFields' || key == 'generalStatus' || key == 'inputType') {
            this.form[key] = 1
          } else if (key == 'items') {

          } else {
            this.form[key] = ''
          }
      }
      this.dialogVisible = true
    },
    updateSubmitForm (form) { // 修改检查项
      this.$refs[form].validate((valid) => {
        if (valid) {
          let flag = false
          for (let i = 0; i < this.form.items.length; i++) {
            if (this.form.items[i].type == 1) flag = true
            if (!this.form.items[i].content && !this.form.items[i].type) {
              this.form.items.splice(i, 1)
            } else if (!this.form.items[i].content || !this.form.items[i].type) {
              this.$message.warning('有数据没填完哦');
              return false
            }
          }
          if (!flag && this.form.inputType != 2) {this.$message.warning('至少有一项正常项');return false}
          if ((this.form.inputType==0 || this.form.inputType==1) && (this.form.items.length === 0)) {
            this.$message.warning('请至少填写一行子表内容');
            return false
          }
          console.log(this.form)
          checkItemUpdate(this.form).then(res => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.dialogVisible = false
              this.getJianchaList()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    submitForm (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let flag = false
          for (let i = 0; i < this.form.items.length; i++) {
            if (this.form.items[i].type == 1) flag = true
            if (!this.form.items[i].content && !this.form.items[i].type) {
              this.form.items.splice(i, 1)
            } else if (!this.form.items[i].content || !this.form.items[i].type) {
              this.$message.warning('有数据没填完哦');
              return false
            }
          }
          if (!flag && this.form.inputType != 2) {this.$message.warning('至少有一项正常项');return false}
          if ((this.form.inputType==0 || this.form.inputType==1) && (this.form.items.length === 0)) {
            this.$message.warning('请至少填写一行子表内容');
            return false
          }
          checkItemSave(this.form).then(res => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.dialogVisible = false
              this.getJianchaList()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    clearDialogForm() {
      this.$refs.form.resetFields()
      this.form.items = [{
        content: '',
        type: '',
        takingPictures: 2,
        describe: 2,
        timestamp: Date.now()
      }]
      for(let key in this.chosedRow) {
        delete this.chosedRow[key]
      }
    },
    addNewRow () {
      this.form.items.push({
            content: '',
            type: '', // 分类1 正常 2 异常
            takingPictures: 2, // 1 强制拍照 2 忽略拍照
            describe: 2, // 是否补充描述 1是 2 否
            timestamp: Date.now()
        })
    },
    handleCurrentChange (val) {
        this.searching.current = val
        this.objCopy()
        this.getJianchaList()
    },
    handleSizeChange (val) {
        this.searching.current = 1
        this.searching.size = val
        this.objCopy()
        this.getJianchaList()
    },
    delRow () {
      let select = this.$refs.DeleteTableSelect.selection
      if(select&&select.length<1){
        this.$message.error('请先勾选要删除的数据')
      }
      console.log(select);
      this.$confirm(`是否确认删除这${select.length}条数据?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        let Allarray = this.form.items
        if(this.title == 1){
          console.log(this.form.items)
          let add = Allarray.filter(item => !select.some(ele=>ele.timestamp===item.timestamp));
          this.form.items = add
        }else{
          let add = Allarray.filter(item => !select.some(ele=>ele.itemId===item.itemId));
          this.form.items = add
        }
      })
    }
  }
}
</script>
 
<style scoped lang="less">
 .used {
   color: #0C235B;
 }
 .unused {
   color: #FF5400;
 }
</style>