import axios from '@/common/js/request'

export function checkItemSave(data){  // 新增检查项
    const checkItemSave = axios({
        url: '/checkItem/saveItem',
        method: 'post',
        data
    })
    return checkItemSave
}

export function checkItemUpdate(data){  // 修改检查项
    const checkItemUpdate = axios({
        url: '/checkItem/updateItem',
        method: 'post',
        data
    })
    return checkItemUpdate
}

export function selectItemList(params){  // 检查项列表
    const selectItemList = axios({
        url: '/checkItem/selectItem',
        method: 'get',
        params
    })
    return selectItemList
}
export function updateStatus(params){  // 检查项停用-启用按钮
    const updateStatus = axios({
        url: '/checkItem/updateStatus',
        method: 'get',
        params
    })
    return updateStatus
}